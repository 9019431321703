import type { VaaConfig } from '~/typesManual/config/vaa'

const vaaDefaults: VaaConfig = {
  theme: 'light',
  pages: {
    index: {
      sampleAnswersAndDuel: true,
      candidatesContactUsPromo: true,
    },
    test: {
      argumentsForAndAgainst: true,
    },
    result: {
      partyMatch: true,
    },
    profile: {
      electionSpecificQuestions: true,
      whyImRunning: true,
    },
    ballot: true,
  },
}

export default vaaDefaults
